<template>
  <div class="modal user_box_up fade" id="viewpermission" tabindex="-1" aria-labelledby="viewtempLabel" aria-hidden="true"
    data-bs-backdrop="static" data-bs-keyboard="false">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="viewtempLabel">
            {{ roleName }}
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body details_eval cust_scroll">
          <div class="pl_18">
            <h4 class="font_size_20 mb-3">
              Permissions
            </h4>
          </div>
          <div>
            <ul>
              <li v-if="grantedPermissions['admin_center']">Admin center</li>
              <table class="table" v-if="grantedPermissions['admin_center']">
                <thead>
                  <tr class="text-center">
                    <th scope="col"></th>
                    <th scope="col">View</th>
                    <th scope="col">Edit</th>
                    <th scope="col">Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="grantedPermissions['admin_center'].includes('User Roles View') || grantedPermissions['admin_center'].includes('User Roles Edit') || grantedPermissions['admin_center'].includes('User Roles Delete')">
                    <td>User Roles</td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('User Roles View')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('User Roles Edit')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('User Roles Delete')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['admin_center'].includes('Users View') || grantedPermissions['admin_center'].includes('Users Edit') || grantedPermissions['admin_center'].includes('Users Delete')">
                    <td>Users</td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Users View')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Users Edit')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Users Delete')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['admin_center'].includes('Clients View') || grantedPermissions['admin_center'].includes('Clients Edit') || grantedPermissions['admin_center'].includes('Clients Delete')">
                    <td>Clients</td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Clients View')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Clients Edit')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Clients Delete')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['admin_center'].includes('Email Templates View') || grantedPermissions['admin_center'].includes('Email Templates Edit') || grantedPermissions['admin_center'].includes('Email Templates Delete')">
                    <td>Email Templates</td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Email Templates View')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Email Templates Edit')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['admin_center'].includes('Email Templates Delete')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="grantedPermissions['admin_center'].includes('Send Notification')">
                    <td>Send Notification</td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Send Notification')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                  <tr v-if="grantedPermissions['admin_center'].includes('Audit Log Viewer')">
                    <td>Audit Log Viewer</td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Audit Log Viewer')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td></td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Bulk Delete Audit Logs')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr v-if="grantedPermissions['admin_center'].includes('Bulk User Role Update')">
                    <td>Bulk User Role Update</td>
                    <td>
                      <div class="text-center" v-if="grantedPermissions['admin_center'].includes('Bulk User Role Update')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td colspan="2"></td>
                  </tr>
                </tbody>
              </table>
              <li v-if="grantedPermissions['dashboard_pages']">Dashboard Pages</li>
              <ul>
                <li v-for="(label, indx) in grantedPermissions['dashboard_pages']" :key="indx">{{ label }}</li>
              </ul>
              <li v-if="grantedPermissions['pbe_management']">PBE Management Dashboard Pages</li>
              <ul>
                <li v-for="(label, indx) in grantedPermissions['pbe_management']" :key="indx">{{ label }}</li>
              </ul>
              <li v-if="grantedPermissions['evaluations']">Evaluations</li>
              <ul>
                <li v-for="(label, indx) in grantedPermissions['evaluations']" :key="indx">{{ label }}</li>
              </ul>
              <li v-if="grantedPermissions['reports_in_review']">Reports in Review</li>

              <table class="table" v-if="grantedPermissions['reports_in_review']">
                <thead>
                  <tr class="text-center">
                    <th scope="col"></th>
                    <th scope="col">Edit Status</th>
                    <th scope="col">View Data Set</th>
                    <th scope="col">Edit Report</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - In Review') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - In Review') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - In Review')">
                    <td>In Review</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - In Review')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - In Review')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - In Review')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Re-Review') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Re-Review') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Re-Review')">
                    <td>Re-Review</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Re-Review')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Re-Review')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Re-Review')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Ready for 3rd Party QC') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Ready for 3rd Party QC') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Ready for 3rd Party QC')">
                    <td>Ready for 3rd Party QC</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Ready for 3rd Party QC')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Ready for 3rd Party QC')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Ready for 3rd Party QC')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Evaluator Reviewed') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Evaluator Reviewed') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Evaluator Reviewed')">
                    <td>Evaluator Reviewed</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Evaluator Reviewed')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Evaluator Reviewed')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Evaluator Reviewed')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Evaluator QC Reviewed') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Evaluator QC Reviewed') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Evaluator QC Reviewed')">
                    <td>Evaluator QC Reviewed</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Evaluator QC Reviewed')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Evaluator QC Reviewed')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Evaluator QC Reviewed')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Finalized') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Finalized') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Finalized')">
                    <td>Finalized</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Finalized')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Finalized')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Finalized')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Hidden') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Hidden') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Hidden')">
                    <td>Hidden</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Hidden')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Hidden')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Hidden')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Declined') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Declined') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Declined')">
                    <td>Declined</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Declined')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Declined')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Declined')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                  <tr
                    v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Pending Client Approval') || grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Pending Client Approval') || grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Pending Client Approval')">
                    <td>Pending Client Approval</td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Status - Pending Client Approval')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - View Data Set - Pending Client Approval')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                    <td>
                      <div class="text-center"
                        v-if="grantedPermissions['reports_in_review'].includes('Reports in Review - Edit Report - Pending Client Approval')">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="20"
                          zoomAndPan="magnify" viewBox="0 0 30 30.000001" height="20" preserveAspectRatio="xMidYMid meet"
                          version="1.0" id="IconChangeColor">
                          <defs>
                            <clipPath id="id1">
                              <path
                                d="M 2.328125 4.222656 L 27.734375 4.222656 L 27.734375 24.542969 L 2.328125 24.542969 Z M 2.328125 4.222656 "
                                clip-rule="nonzero" id="mainIconPathAttribute" filter="url(#shadow)"></path>
                            </clipPath>
                          </defs>
                          <g clip-path="url(#id1)">
                            <path fill="rgb(13.729858%, 12.159729%, 12.548828%)"
                              d="M 27.5 7.53125 L 24.464844 4.542969 C 24.15625 4.238281 23.65625 4.238281 23.347656 4.542969 L 11.035156 16.667969 L 6.824219 12.523438 C 6.527344 12.230469 6 12.230469 5.703125 12.523438 L 2.640625 15.539062 C 2.332031 15.84375 2.332031 16.335938 2.640625 16.640625 L 10.445312 24.324219 C 10.59375 24.472656 10.796875 24.554688 11.007812 24.554688 C 11.214844 24.554688 11.417969 24.472656 11.566406 24.324219 L 27.5 8.632812 C 27.648438 8.488281 27.734375 8.289062 27.734375 8.082031 C 27.734375 7.875 27.648438 7.679688 27.5 7.53125 Z M 27.5 7.53125 "
                              fill-opacity="1" fill-rule="nonzero" id="mainIconPathAttribute"></path>
                          </g>
                          <filter id="shadow">
                            <feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" flood-color="black">
                            </feDropShadow>
                          </filter>
                        </svg>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <li v-if="grantedPermissions['site_pages']">Site</li>
              <ul>
                <li v-for="(label, indx) in grantedPermissions['site_pages']" :key="indx">{{ label }}</li>
              </ul>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RolePermissionModal",
  props: ["grantedPermissions", "roleName"],
}
</script>